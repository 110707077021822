<template>
  <div>
    <header>
      <nav class="level dashboard-navbar">
        <div class="level-left">
          <div class="level-item">
            <div class="content">
              <h1 class="title has-text-primary">
                <b-icon :icon="icon" />
                {{ title }}
              </h1>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <main role="main">
      <section class="section">
        <div class="container">
          <div class="columns is-centered is-vcentered">
            <div class="column is-three-fifths">
              <slot name="content" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'ErrorPageLayout',

  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },
};
</script>
